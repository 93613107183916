require('../css/index.css');

var Tabulator = require('tabulator-tables');

BX24.init(function () {
    var filterField;
    var filterValue;

    var table = new Tabulator('#table', {
        height: 500,
        layout: 'fitColumns',
        pagination: 'remote',
        paginationSize: 50,
        ajaxURL: '/list',
        ajaxSorting: true,
        ajaxFiltering: true,
        columns: [
            { title: "Серийный номер F", field: "PROPERTY_124" },
            { title: "Серийный номер S", field: "PROPERTY_122" },
            { title: "Модель", field: "NAME" },
            { title: "Бренд", field: "PROPERTY_146" },
            { title: "Дистрибьютор", field: "PROPERTY_130" },
            { title: "Конечный заказчик", field: "PROPERTY_120" },
            { title: "Местонахождение заказчика", field: "PROPERTY_174" },
        ],
        rowClick: function (e, row) { //trigger an alert message when the row is clicked
            BX24.openApplication(
                {
                    // данные, передаваемые открываемому приложению
                    'opened': "details/" + row.getData().ID 
                },
                function()
                {
                    // этот обработчик сработает, когда приложение будет закрыто
                    // alert('Application closed!')
                }
            );
            // location.href = "details/" + row.getData().ID;
        },
    });

    // кнопка поиска. Функция
    let tabulatorStart = function () {
        filterField = document.getElementById('filter-field').value; // "PROPERTY_124";//
        if (document.getElementById('filter-value-hidden').value != false) {
            filterValue = document.getElementById('filter-value-hidden').value; // значение id
        } else {
            filterValue = document.getElementById('filter-value').value;        // значение title
        }

        table.setFilter(filterField, "=", filterValue);
    };

    // кнопка очищения. Функция
    let clearButtonClick = function () {
        document.getElementById('filter-value').value = "";

        table.clearFilter();
    };  

    // получаем значение выбранного селекта
    var filterDisFun = function () {
        let n = document.getElementById('filter-field').options.selectedIndex; // индекс выбранного оптионс
        let filterField = document.getElementById('filter-field').options[n].value; // получаем value по индексу
        
        // проверяем если он оказался "дистрибьютор", то открываем диалоговое окно для выбора точного дистр-а
        if (filterField == 'PROPERTY_130') {
            BX24.selectCRM({
                entityType: ['company'],
            }, function () {
                //console.log(arguments);
                filterDis.value = arguments[0].company[0].title;
                
                // убираем "CO_"
                let idDistr = arguments[0].company[0].id;
                idDistr = idDistr.substr(3);
                document.getElementById('filter-value-hidden').value = idDistr;
                
                // distrField.setAttribute('data-company', distrFieldNewAttr);
            });
        }

    };

    // кнопка поиска. Слушатель
    let tabulatorButtonClick = document.getElementById('tabulatorButton');
    tabulatorButtonClick.addEventListener('click', tabulatorStart, false);

    // кнопка очищения. Слушатель
    let clearButton = document.getElementById("clearButton");
    clearButton.addEventListener("click", clearButtonClick, false);

    //Слушатель input-а
    var filterDis = document.getElementById('filter-value');
    filterDis.addEventListener('click', filterDisFun, true);

});
 
// Открываем форму для дбавления записи
var addEntry = function () {

    // открываем форму записи
    BX24.openApplication(
        {
            // данные, передаваемые открываемому приложению
            'opened': "details/0/" 
        },
        function()
        {
            // этот обработчик сработает, когда приложение будет закрыто
            // alert('Application closed!')
        }
    );
    //location.href = ('details/0/');
}; 

//кнопка добавить. Слушатель
var addEntryBatton = document.getElementById('addEntryId');
addEntryBatton.addEventListener('click', addEntry, false);



